<template>
    <div>
        <Header :instanceInfo="instanceInfo" :displayLoginForm="false" />
        <div class="container">
            <div class="col-md-12">
                <h2 class="pink">Log in</h2>
                <div class="col-md-4">
                    <div class="row">
                        <form accept-charset="UTF-8" @submit.prevent="submitLogin">
                            <label for="email">Email</label>
                            <input v-model="loginUsername" class="form-control" placeholder="Email" name="email"
                                type="text" id="email" required>
                            <label for="password">Password</label>
                            <input v-model="loginPassword" class="form-control" maxlength="100" placeholder="Password"
                                name="password" type="password" id="password" required>
                            <br>
                            <button type="submit" class="btn btn-success">Sign In</button>
                            <br>
                            or <router-link to="/">Create a new {{ instanceInfo.name }}.com Account</router-link>
                            <br>
                            <br>
                        </form>
                        <br>
                        <div v-if="loginFailed" class="alert alert-danger alert-dismissible">
                            <button @click="clearFailedMessage" type="button" class="close" data-dismiss="alert"
                                aria-label="Close"><span aria-hidden="true">×</span></button>
                            <p>Provided credentials are incorrect!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs"/>
    </div>
</template>

<script>
import { ref } from 'vue';
import { loginRequest } from '@/app/api/auth';
import { useUser } from '@/app/use/user';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Login',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        displayLoginForm: {
            type: Boolean,
            required: false,
            default: true,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup() {
        const { isLogged, login } = useUser();
        const loginUsername = ref('');
        const loginPassword = ref('');

        const isLoggingIn = ref(false);
        const loginFailed = ref(false);

        const submitLogin = () => {
            loginRequest({
                username: loginUsername.value,
                password: loginPassword.value,
            })
                .then(response => {
                    login(response);
                    return response;
                })
                .catch(() => { loginFailed.value = true; })
                .finally(() => (isLoggingIn.value = false));
        };

        const clearFailedMessage = () => {
            loginFailed.value = false;
        };

        return {
            loginUsername,
            loginPassword,
            loginFailed,
            isLogged,
            submitLogin,
            clearFailedMessage,
        };
    },
};
</script>
